import React from "react";
import './MobileGraphic.scss'
import {RiMenuLine} from "react-icons/all";

export default class MobileGraphic extends React.Component<any, any> {
    render() {
        return (
            <div className="card isometric">
                <nav className="micro-nav float drop-shadow">
                    <section className="hamburger-container flex-grid column alignment-layout-center">
                        <RiMenuLine/>
                    </section>
                </nav>
                <div className="float content box drop-shadow start-slow">
                    <p className="black-text">██████████ ██████ ██████████ </p>
                    <p className="black-text">████ ██████████ ██████████ </p>
                    <p className="black-text">█████ ██████ <span className="blue-text">████</span> ███ ███ </p>
                </div>
                <div className="float content box drop-shadow start-slowest">
                    <p className="black-text">███████ ████ ████ ███ ███████ </p>
                <p className="black-text">████ ████ ████ ██ ████████</p>
                <p className="black-text">███ █████ ███ ███ ██████████ </p>
                </div>
                <div className="float content button drop-shadow start-slow"/>
            </div>
        );
    }
}
