import React from "react";
import './WebdevGraphic.scss'
import {RiBlazeLine, RiCheckboxBlankLine, RiCloseLine, RiRainbowLine, RiSubtractLine} from "react-icons/all";

export default class WebdevGraphic extends React.Component<any, any> {
    render() {
        return (
            <article className="website-screen-box flex">
                <div className="website-screen">
                    <div className="website-header flex">
                        <RiSubtractLine/> <RiCheckboxBlankLine/> <RiCloseLine/>
                    </div>
                    <div id="screen1" className="float-web black-text">
                        <p>████████████</p>
                        <p>████████</p>
                        <p>█████████</p>
                        <p>████</p>
                        <p>█████████</p>
                    </div>
                    <div id="screen2" className="float-web black-text">
                        <RiRainbowLine/>
                    </div>
                    <div id="screen3" className="float-web black-text">
                        <RiBlazeLine />
                    </div>
                    <div id="screen4" className="float-web black-text">
                    </div>
                    <div id="screen5" className="float-web black-text">
                        <p>██████ ██████ ████ ██ ███ ████████ ████████████████</p>
                        <p>█████████████ ███████ █████ ████████████</p>
                        <p>██ █ ███ ███ ██████ ███████ ███ █████ ████████ ██████████</p>
                    </div>
                    <div id="screen6" className="float-web black-text">
                        <p>██ ████ █████████ █████ ██ ███ █████ ████████ ██████████</p>
                        <p>████ ██████ ████ ██ ███████████ █████ ███ ████████</p>
                        <p>█████████████ ████████████ ████ ████████</p>
                    </div>
                    <div id="screen7" className="float-web">
                        <div id="button_web1"/>
                        <div id="button_web2"/>
                    </div>
                </div>
            </article>
        );
    }
}
