import React from 'react';
import './divider.css';

export default class Divider extends React.Component{
    render() {
        return (
            <div className="divider flex-grid"/>
        );
    }
}
