import React from 'react';
import Divider from "./atom/Divider/Divider";

export class WhoAmI extends React.Component {
    render() {
        return (<section className="w50">
            <h1>Bas Sarrechia.</h1>
            Full-stack developer

            <p>Hi! I create applications and web applications.<br/>
                As Junior developer for JArchitects I aim to provide quality solutions for our customers.<br/>
                I'm eager to learn and love to build new projects using the most innovating technologies in Java!<br/>
                Not convinced? Look below!</p>
            <Divider/>
        </section>);
    }
}
