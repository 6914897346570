import React from "react";
import './ShowcaseCard.scss'

export default class ShowcaseCard extends React.Component<any, any> {
    render() {
        return (
            <section className="flex-1 flex parent">
                <section className="showcase-card">

                    <text className="text-content">
                        <h2>
                            Bas Sarrechia.
                        </h2>
                        <p> As Junior developer for JArchitects I aim to provide quality solutions for our customers.<br/>
                            I'm eager to learn and love to build new projects using the most innovating technologies in
                            Java!<br/>
                            Quality is number one, do it right or do it twice.
                        </p>
                    </text>

                    <div className="square square-1"/>
                    <div className="square square-2"/>

                </section>
                <h1 className="h1-title flex">
                    I am a
                    <br/>developer
                </h1>
            </section>
        );
    }

}
