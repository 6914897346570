import React from 'react';
import './App.scss';
import './Component/whoami'
import WebdevGraphic from "./Component/atom/SkillGraphics/Web/WebdevGraphic";
import MobileGraphic from "./Component/atom/SkillGraphics/Mobile/MobileGraphic";
import ShowcaseCard from "./Component/molecules/ShowcaseCard";
import Company from "./Component/molecules/Company";

function App() {
    return (
        <body className=" background">
        <header className="flex-1">
            <ShowcaseCard/>
        </header>
        <article className="flex-1 spacer">
            <Company/>
        </article>

        <article className="flex spacer-large flex-sm-col margin-20">
            <section className="flex-3 flex-col">


                <p>
                    Software is my specialization, namely Java.<br/>
                    Using the Spring framework, I provide modern solutions.<br/>
                    Be it a small business application, or gigantic project.<br/>
                    ps. I can make a cross-platform desktop application too using JavaFx!
                </p>
            </section>
            <section className="flex-3 flex-col">
                <WebdevGraphic/>
                <p>
                    I like SPA!<br/>
                    Single page applications are a modern web solution.<br/>
                    these single page applications are faster than regular websites, since only small portions load.<br/>
                    I use Angular or React to achieve this!
                </p>
            </section>
            <section className="flex-3 flex-col">
                <MobileGraphic/>
                <p>
                    I use the newest technology for mobile development, namely Flutter.<br/>
                    Flutter supports iOS and Android at the same time!<br/>
                    With a near native performance, you're not missing out!
                </p>
            </section>
        </article>
        </body>
    );
}

export default App;
