import React from "react";
import './ShowcaseCard.scss'

export default class Company extends React.Component<any, any> {
    render() {
        return (
            <section className="flex-1 flex ">
                <h1 className="h1-title flex right"  >
                    For an amazing<br/>
                    company
                </h1>
                <a href="https://www.jarchitects.be" className="showcase-card hero centered showcase-card-right right-right">
                    <img className="hero-image" src="https://jarchitects.be/wp-content/uploads/2020/05/Fichier-4.png" alt="jarchitects_logo"/>
                </a>
            </section>
        );
    }

}
